import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { ChevronRightSvg } from 'Assets/svg/feedPage/ChevronRightSvg';
import { LeftArrowSvg } from 'Assets/svg/rcInvite/LeftArrowSvg';

import colors from 'Static/typography/colors';

const StyledArrowButton = styled.button`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 2.25rem;
	height: 2.25rem;
	border-radius: 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.7s ease;

	&:not(.flat-arrows) {
		background: ${colors.WHITE};
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
	}

	svg {
		width: 1rem;
		height: 1rem;
		stroke: ${colors.GREY_DS.G2};
		transition: all 0.2s ease-in-out 0s;
	}

	&.disabled {
		display: none;
	}

	&.prev-button {
		svg {
			transform: translateX(-50%);
			transform: rotate(180deg);
		}
	}

	&:hover {
		&:not(.flat-arrows) {
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
		}
	}
`;

type AriaProps = {
	label?: string;
	// ID if the button is used to control an element.
	controls?: string;
};

type Props = {
	className?: string;
	flatArrows?: boolean;
	onClick?: () => void;
	icon?: any;
	disabled?: boolean;
	aria?: AriaProps;
};

const ArrowButton = ({
	className,
	flatArrows,
	onClick,
	icon,
	disabled,
	aria,
}: Props) => {
	const arrowClassNames = classNames(className, {
		'flat-arrows': flatArrows,
		disabled,
	});

	return (
		<StyledArrowButton
			className={arrowClassNames}
			onClick={!disabled ? onClick : undefined}
			aria-label={aria?.label}
			aria-controls={aria?.controls}
		>
			{icon || (flatArrows ? <LeftArrowSvg /> : <ChevronRightSvg />)}
		</StyledArrowButton>
	);
};

export default ArrowButton;
