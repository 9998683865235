import React from 'react';
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';

import ArrowButton from './arrowButton';

const ArrowButtonListWrapper = styled.div<{ $top: string }>`
	position: relative;

	.arrow-button-list {
		width: calc(100% + 0.75rem);
		height: 100%;
		overflow: hidden;
		margin: 0;
		transform: translateX(-0.75rem);

		&.flat-arrows {
			width: 'calc(100% - 6.25rem)';
		}
	}

	.prev-button-wrapper,
	.next-button-wrapper {
		height: 2.25rem;
		position: absolute;
		top: ${({ $top }) => $top};
		bottom: 100%;
		cursor: pointer;
		z-index: 5;
		transform: translateY(-50%);
	}

	.prev-button-wrapper {
		left: -1.25rem;
	}

	.next-button-wrapper {
		right: -1.25rem;
	}

	.adjust-top {
		top: 8.625rem;

		.arrow-button {
			margin-top: -1.25rem;
		}
	}
`;

const ArrowButtonWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 2.25rem;
	z-index: 10;
`;

type Props = {
	children?: React.ReactNode;
	hidePrev?: boolean;
	hideNext?: boolean;
	onPrev?: (...args: any[]) => any;
	isCollectionCarousel?: boolean;
	isSubCategoryCarousel?: boolean;
	onNext?: (...args: any[]) => any;
	flatArrows?: boolean;
	isCardsArrowsRequired?: boolean;
	icon?: any;
	top?: string;
};

const ArrowButtonList = ({
	children,
	onNext,
	onPrev,
	flatArrows,
	isCollectionCarousel,
	hidePrev,
	hideNext,
	isCardsArrowsRequired = true,
	icon = null,
	top = '10rem',
}: Props) => (
	<ArrowButtonListWrapper $top={top} className='arrow-button-list-wrapper'>
		<Conditional if={isCardsArrowsRequired && !hidePrev}>
			<ArrowButtonWrapper
				className={`arrow-button-wrapper prev-button-wrapper ${
					isCollectionCarousel ? 'adjust-top' : ''
				}`}
				onClick={onPrev}
			>
				<ArrowButton
					icon={icon}
					className='prev-button'
					flatArrows={flatArrows}
					aria={{
						label: 'Previous',
					}}
				/>
			</ArrowButtonWrapper>
		</Conditional>

		<Conditional if={isCardsArrowsRequired && !hideNext}>
			<ArrowButtonWrapper
				className={`arrow-button-wrapper next-button-wrapper ${
					isCollectionCarousel ? 'adjust-top' : ''
				}`}
				onClick={onNext}
			>
				<ArrowButton
					icon={icon}
					className='next-button'
					flatArrows={flatArrows}
					aria={{
						label: 'Next',
					}}
				/>
			</ArrowButtonWrapper>
		</Conditional>

		<Conditional if={children}>
			<div
				className={`arrow-button-list ${
					flatArrows ? 'flat-arrows' : ''
				}`}
			>
				{children}
			</div>
		</Conditional>
	</ArrowButtonListWrapper>
);

export default ArrowButtonList;
