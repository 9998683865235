import React from 'react';
import styled from 'styled-components';

import { LeftArrowSvg, RightArrowSvg } from 'Assets/svg/productPage';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';

const NavigationArrowsContainer = styled.div`
	margin-left: 1rem;
	display: flex;
`;

const ArrowButtonContainer = styled.button<{ $size?: 'default' | 'small' }>`
	--button-size: ${({ $size }) => ($size === 'small' ? '1.75rem' : '2rem')};
	--icon-size: ${({ $size }) => ($size === 'small' ? '0.75rem' : '1rem')};

	cursor: pointer;
	width: var(--button-size);
	height: var(--button-size);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${colors.GREY_DS.G6};
	border-radius: 50%;

	svg {
		width: var(--icon-size);
		height: var(--icon-size);

		path {
			stroke: ${colors.GREY_DS.G3};
		}
	}

	&:first-child {
		margin-right: 0.5rem;

		svg {
			margin-right: 0.0625rem;
		}
	}

	&:last-child {
		svg {
			margin-left: 0.0625rem;
		}
	}

	&:hover {
		border-color: ${colors.GREY_DS.G5};
	}

	&:disabled {
		svg > path {
			stroke: ${colors.GREY_DS.G6};
		}
	}
`;

type Props = {
	showLeftArrow: boolean;
	showRightArrow: boolean;
	prevSlide?: () => void;
	nextSlide?: () => void;
	size?: 'default' | 'small';
};

const NavigationButtons = ({
	showLeftArrow,
	showRightArrow,
	prevSlide,
	nextSlide,
	size = 'default',
}: Props) => {
	return (
		<NavigationArrowsContainer>
			<ArrowButtonContainer
				disabled={!showLeftArrow}
				onClick={prevSlide}
				aria-label={strings.PREVIOUS}
				aria-disabled={!showLeftArrow}
				$size={size}
			>
				<LeftArrowSvg />
			</ArrowButtonContainer>
			<ArrowButtonContainer
				disabled={!showRightArrow}
				onClick={nextSlide}
				aria-label={strings.NEXT}
				aria-disabled={!showRightArrow}
				$size={size}
			>
				<RightArrowSvg />
			</ArrowButtonContainer>
		</NavigationArrowsContainer>
	);
};

export default NavigationButtons;
