import * as React from 'react';

export const LeftArrowSvg = (props: any) => {
	return (
		<svg width={12} height={20} viewBox='0 0 12 20' {...props}>
			<path
				fill='#BABABA'
				fillRule='evenodd'
				stroke='#BABABA'
				d='M11 18.14l-1.094 1.146L1 9.643 9.906 0 11 1.145 3.187 9.643z'
			/>
		</svg>
	);
};
