import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';

import useOnScreen from 'Hooks/useOnScreen';
import {
	getLazyloadOverrideStatus,
	isBot as checkIfBot,
} from 'Utils/stateUtils';

const DEFAULT_PLACEHOLDER_HEIGHT = '22rem';

const PlaceHolder = styled.div``;

const LazyComponent = ({
	children,
	target = 'USER',
	placeHolderHeight = DEFAULT_PLACEHOLDER_HEIGHT,
	id,
}: PropsWithChildren<{
	/**
	 * Target:
	 *
	 * **BOT**: Lazy Loads for Bot;
	 *
	 * **USER**: Lazy Loads for User;
	 *
	 * **BOTH**: Lazy Loads for All UAs;
	 *
	 * **NONE**: No action, renders as usual for all UAs
	 **/
	target?: 'BOT' | 'USER' | 'BOTH' | 'NONE';
	placeHolderHeight?: string;
	id?: string;
}>) => {
	const lazyElementRef = useRef<HTMLDivElement>(null);
	const isIntersecting = useOnScreen({
		ref: lazyElementRef,
		disconnectAfterOneIntersection: true,
	});
	const { isBot, overrideLazyLoad } = useSelector(state => {
		return {
			isBot: checkIfBot(state),
			overrideLazyLoad: getLazyloadOverrideStatus(state),
		};
	});

	const shouldRender = useMemo(() => {
		switch (true as boolean) {
			case overrideLazyLoad:
				return true;
			case isBot && target === 'USER':
			case !isBot && target === 'BOT':
				return true;
			case target === 'BOT' && isBot && isIntersecting:
			case target === 'USER' && !isBot && isIntersecting:
			case target === 'BOTH' && isIntersecting:
			case target === 'NONE':
				return true;
			default:
				return false;
		}
	}, [isBot, target, isIntersecting, overrideLazyLoad]);

	// TODO: After React 18 Upgrade, move this block to work with `useTransition` + actual shimmer placeholder.
	return (
		<div id={id}>
			<Conditional if={shouldRender}>{children}</Conditional>
			<Conditional if={!shouldRender}>
				<PlaceHolder
					style={{
						minHeight:
							placeHolderHeight || DEFAULT_PLACEHOLDER_HEIGHT,
					}}
					ref={lazyElementRef}
				/>
			</Conditional>
		</div>
	);
};

export default LazyComponent;

export const WrapInLazyComponent = (children: any) => {
	return <LazyComponent>{children}</LazyComponent>;
};
