import React from 'react';
import { connect } from 'react-redux';
import Head from 'next/head';
import stringToReactElement from 'htmr';

import { checkNoIndex } from 'Utils/hostUtils';
import {
	getBannerImage,
	getTwitterCardAndOGImageMetaTags,
} from 'Utils/metaTagUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getHost } from 'Utils/stateUtils';
import {
	getWhitelistedCanonicalQueryParams,
	replaceTestingEnvURLwithProductionURL,
} from 'Utils/urlUtils';

import {
	BANNER_IMAGE_URLS_CITY_PAGE_DESKTOP,
	BANNER_IMAGE_URLS_CITY_PAGE_MOBILE,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

const HelmetUtilities = (props: any) => {
	const {
		metaDescription: tempMetaDescription,
		isNoIndex,
		title: tempTitle,
		metaTags,
		canonicalUrl: canonicalUrlFromServer,
		scripts = [],
		currentUrl = '',
		host,
		metaDescriptionTags,
		metaTagString,
		children,
		removeCanonical = false,
		pageNo,
		pageSortingParams,
		isNoIndexFollow = false,
		openGraphImageParamters = {
			imageData: {},
		},
		nextUrl,
		prevUrl,
	} = props;

	const canonicalUrl = React.useMemo(() => {
		// this updates canonical URL on client side route change (usually for filters/sort/pagination)
		const queryParams =
			getWhitelistedCanonicalQueryParams(pageSortingParams);
		if (queryParams) {
			const [url] = canonicalUrlFromServer?.split?.('?') ?? [];
			return `${url}${queryParams}`;
		}

		return canonicalUrlFromServer;
	}, [pageSortingParams, canonicalUrlFromServer]);

	const title = pageNo
		? `${tempTitle}${strings.formatString(strings.PAGE_NO_META, pageNo)}`
		: tempTitle;
	const metaDescription = pageNo
		? `${tempMetaDescription}${strings.formatString(
				strings.PAGE_NO_META,
				pageNo,
		  )}`
		: tempMetaDescription;

	const getMetaRobotsContent = () => {
		const isPageNoIndexed = checkNoIndex({ host, isNoIndex });
		switch (true) {
			case isPageNoIndexed && isNoIndexFollow:
				return 'noindex, follow';
			case isPageNoIndexed:
				return 'noindex';
			default:
				return 'index, follow';
		}
	};

	const getOGImageAndTwitterCardMetaString = () => {
		const { imageData, title, url } = openGraphImageParamters;
		const isDesktop = PlatformUtils.isDesktop();
		let cardImageData = imageData;
		if (!imageData?.image)
			cardImageData = getBannerImage({
				imageUrl: isDesktop
					? BANNER_IMAGE_URLS_CITY_PAGE_DESKTOP[0]
					: BANNER_IMAGE_URLS_CITY_PAGE_MOBILE[0],
				width: 768,
				desktopImageHeight: 319,
				mobileImageHeight: 895,
			});
		const cardTitle = title ?? tempTitle;
		const cardURL = url ?? currentUrl;
		const tags = getTwitterCardAndOGImageMetaTags({
			imageData: cardImageData,
			title: cardTitle?.replaceAll('"', "'"),
			url: cardURL,
		});
		return tags.reduce((tag, allTags) => `${allTags} \n ${tag}`, '');
	};

	return (
		<Head>
			<title translate='no'>{title || 'Headout'}</title>
			{canonicalUrl ? (
				<link
					rel='canonical'
					href={replaceTestingEnvURLwithProductionURL(canonicalUrl)}
				/>
			) : !removeCanonical ? (
				<link
					rel='canonical'
					href={replaceTestingEnvURLwithProductionURL(currentUrl)}
				/>
			) : null}
			{nextUrl ? <link rel='next' href={nextUrl} /> : null}
			{prevUrl ? <link rel='prev' href={prevUrl} /> : null}
			{metaDescriptionTags
				? metaDescriptionTags.map((item: any, index: any) => (
						<meta key={index} {...item} />
				  ))
				: null}
			{metaDescription ? (
				<meta name='description' content={metaDescription} />
			) : null}
			{<meta name='robots' content={getMetaRobotsContent()} />}
			{metaTags
				? metaTags.map((item: any) => {
						/* Workaround to prevent `&` in query params encoded to `&amp;` */
						/* TODO: replace the `head` tag with `Fragment/RawHTML` 
                once this gets implemented by react team: https://github.com/reactjs/rfcs/pull/129 */
						if (item.hreflang && item.href.indexOf('&') > 0)
							return (
								<head
									dangerouslySetInnerHTML={{
										__html: `<link rel="${item.rel}" hreflang="${item.hreflang}" href="${item.href}"/>`,
									}}
								/>
							);
						return <link key={item.hreflang} {...item} />;
				  })
				: null}
			{scripts?.length &&
				scripts.map((script: any) => (
					<script key={script?.id} {...script} />
				))}
			{stringToReactElement(getOGImageAndTwitterCardMetaString())}
			{metaTagString ? stringToReactElement(metaTagString) : null}
			{children}
		</Head>
	);
};

const mapStateToProps = (state: any) => ({
	host: getHost(state),
});

export default connect(mapStateToProps, null)(HelmetUtilities);
