import React, { useEffect } from 'react';

interface OptionsTypes {
	root?: Element;
	rootMargin?: string;
	threshold: number;
}

export default function useOnScreen({
	ref,
	options,
	disconnectAfterOneIntersection = false,
}: {
	ref: React.MutableRefObject<any>;
	options?: OptionsTypes;
	disconnectAfterOneIntersection?: boolean;
}) {
	const [isIntersecting, setIntersecting] = React.useState(false);
	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setIntersecting(entry.isIntersecting);
			if (entry.isIntersecting && disconnectAfterOneIntersection) {
				observer?.disconnect();
			}
		}, options);
		if (ref?.current && observer) observer.observe(ref.current);

		return () => {
			observer?.disconnect();
		};
	}, [disconnectAfterOneIntersection, options, ref]);

	return isIntersecting;
}
